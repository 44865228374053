<span class="group cursor-pointer flex items-center">
  {{ label }}
  <i
    [ngClass]="
      field === sortField
        ? sortOrder > 0
          ? 'icon-wl-arrow-alt-down text-blue-600'
          : 'icon-wl-arrow-alt-up text-blue-600'
        : 'icon-wl-arrow-alt-down opacity-0 group-hover:opacity-100'
    "></i>
</span>
